import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

${normalize};

  body,
  html, #__next {
    font-size: 14px;
    height: 100%;
  }

  .ant-scrolling-effect {
    .ant-layout-header, .sticky-header { 
      padding-right: 10px;
    }
  }


  body {
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
    background-color: white;
    font-size: 14px;
		line-height: 1.5;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  a {
    text-decoration: none;
    transition: color 0.1s;
  }

  h5, h6 {
    text-align: center;
    font-size: 1em;
  }
  ol, ul {
    padding-inline-start: 1em;
  }
  p {
    line-height: 1.43em;
  }

  .ant-typography code {
    background: none; 
    border: none;
  }

  // More readable text for paragraphs etx
  .basic-text{
    font-family: 'Lato', sans serif;
  }

  .mapboxgl-canvas {
    width: 100% !important;
  }

  .grey {
    color: ${props => props.theme.color.greyText};
  }

  .black {
    color: black;
  }

  .small {
    font-size: 12px;
    line-height: 1.25em;
  }

  // Use if you want to keep two items on the same row together
  .no-wrap{
    white-space: nowrap;
  }

  .grey{
    color: grey;
  }

  // User Pages Checkins List
  .checkins-list {
    overflow: hidden;
  }

  .ant-dropdown{
    border-radius: ${props => props.theme.borderRadius.md};
    box-shadow: ${props => props.theme.boxShadow.card};
    overflow: hidden;
    .ant-menu-item {
      transition: none;
    }
  }
  .ant-alert-info {
    border-width: 2px;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }

  img.lazyload:not([src]) {
    visibility: hidden;
  }
  .hide-on-desktop {
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }

  .mapboxgl-canvas, .mapboxgl-popup-close-button{
    outline: none;
  }
  .mapboxgl-canvas {
    cursor: grab 
  }

  ${'' /* Fix login form browser background color problem */}
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .StripeElement--webkit-autofill {
    background: #fff !important;
  }
  ::selection {
    background: #333; 
    color: white;
  }

  .ant-list-split .ant-list-item{
    border: none;
  }

  .custom-header .ant-list-item {
    padding: 8px 12px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px #e4e4e4;
  }

  .ant-select .ant-select-selector {
    border: transparent !important;
  }
  .ant-input:focus, .ant-input-focused {
    border-color: transparent;
    box-shadow: 0 0 0 2px #e4e4e4;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    border-radius: 4px;
  }
  .ant-checkbox-inner { 
    border-radius:4px;
    border-width: 2px;
    ::after{
      left: 10%;
    }
  }
`;

export default GlobalStyle;
