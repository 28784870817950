import { createGlobalStyle } from 'styled-components';

const DisableScrollStyle = createGlobalStyle`
  body,
  html {
    overflow: hidden;
  }
`;

export default DisableScrollStyle;
